import { decode } from '@msgpack/msgpack'
import { BloomSearch } from '@pacote/bloom-search'
import { stemmer } from 'stemmer'

console.log('foo')

export const onClientEntry = (_, { filename, fetchOptions = {} }) => {
  window.__BLOOM__ =
    window.__BLOOM__ ||
    new BloomSearch({
      errorRate: 0.00001,
      summary: ['path', 'title'],
      stemmer,
    })

  console.log('bar')

  // eslint-disable-next-line no-undef
  return fetch(`${__PATH_PREFIX__}/${filename}`, fetchOptions)
    .then((response) => response.arrayBuffer())
    .then(decode)
    .then((fullIndex) => {
      window.__BLOOM__.load(fullIndex)
      return window.__BLOOM__
    })
    .catch((e) => {
      console.log('Failed fetching the search index.')
      throw e
    })
}
