/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import littlefoot from 'littlefoot'
import './src/fonts.css'
import './src/styles.css'
require('littlefoot/dist/littlefoot.css')

export const onRouteUpdate = () => {
  littlefoot()
}
